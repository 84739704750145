import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '@assets/images/clever-logo.png';
import { IconButton } from '@mui/material';
import { isMobilePlatform } from '@common/globals';
import MobileMenu from './MobileMenu/MobileMenu';
import styles from './NavigationBar.module.css';
import RouteLink from '@components/RouteLink/RouteLink';
import { getContactRoute, getDownloadRoute, getHomeRoute } from '@common/routes';

type Props =  {
  containerRef: React.RefObject<HTMLDivElement>;
  onShowMobileLinkDialog?: () => void;
}

const MenuItem = (props: { children: React.ReactNode, target: string }) => {
  const location = useLocation();
  const selected = location.pathname === props.target;
  return (
    <div className={classNames(styles.MenuItem, selected && styles.Selected)}>
      <RouteLink target={props.target}>
        {props.children}
      </RouteLink>
    </div>
  );
};

const NavigationBar = (props: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleBurgerClick = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseMenu = useCallback(() => {
    setMobileOpen(false);
  }, []);

  const handleMobileLinkClick = useCallback(() => {
    handleCloseMenu();
    props.onShowMobileLinkDialog && props.onShowMobileLinkDialog();
  }, [props.onShowMobileLinkDialog, handleCloseMenu]);

  return (
    <div className={styles.NavigationBar}>
      <div className={styles.Logo}>
        <RouteLink target={getHomeRoute()} >
          <img src={Logo} alt="Clever Logo" />
        </RouteLink>
      </div>

      <div className={styles.Menus}>
        <MenuItem target={getHomeRoute()}>Home</MenuItem>
        {/* <MenuItem target="/pricing">Pricing</MenuItem>
        <MenuItem target="/about">About</MenuItem> */}
        <MenuItem target={getContactRoute()}>Contact</MenuItem>

        {!isMobilePlatform && (
          <RouteLink target={getDownloadRoute()}>
            <div className={styles.DownloadButton}>
              <span>Try for free</span>
            </div>
          </RouteLink>
        )}
      </div>

      <div className={styles.HamburgerMenu} onClick={handleBurgerClick}>
        <IconButton>
          <MenuIcon />
        </IconButton>
      </div>

      <MobileMenu
        open={mobileOpen}
        className={styles.MobileMenu}
        containerRef={props.containerRef}
        onBackdropClick={handleCloseMenu}
        onShowMobileLinkDialog={handleMobileLinkClick}
      />
    </div>
  );
};

export default NavigationBar;
