import classNames from 'classnames';
import React from 'react';
import styles from './SectionContainer.module.css';

type Props = {
  bottomRounded?: boolean;
  className?: string;
  children: React.ReactNode;
  hidden?: boolean;
}

const SectionContainer = (props: Props) => {

  if(props.hidden) { return null; }

  return (
    <div className={classNames(styles.SectionWrapper, props.className, {
      [styles.BottomRounded]: props.bottomRounded
    })}>
      {props.children}
    </div>
  );
};

export default SectionContainer;
