import React from 'react';
import BgDot from '@components/BgDot/BgDot';
import styles from './Footer.module.css';
import RouteLink from '@components/RouteLink/RouteLink';
import { getPrivacyPolicyRoute, getTermsOfUseRoute } from '@common/routes';

const Footer = () => {
  return (
    <div className={styles.FooterContainer}>
      <BgDot right={-319} bottom={-572} />
      <div className={styles.LeftLinks}>
        <RouteLink target={getTermsOfUseRoute()}>Terms of Use</RouteLink>
        <RouteLink target={getPrivacyPolicyRoute()}>Privacy Policy</RouteLink>
      </div>
      <div className={styles.CopyRight}>
        Copyright imClever 2024
      </div>
    </div>
  );
};

export default Footer;
