import React from 'react';
import classNames from 'classnames';
import styles from './BgDot.module.css';

type Props = {
  top?: number | string;
  left?: number | string;
  bottom?: number | string;
  right?: number | string;
  width?: number | string;
  height?: number | string;
  color?: string;
  whiteBackground?: boolean;
  className?: string;
}

const BGDot = (props: Props) => {
  const { top, left, width, height, color, bottom, right, whiteBackground, className } = props;
  return (
    <div
      className={classNames(styles.BSBgDot, {
        [styles.WhiteBackground]: whiteBackground
      }, className)}
      style={{
        top: top,
        left: left,
        width: width ?? 844,
        height: height ?? 844,
        bottom: bottom,
        right: right,
        backgroundColor: color
      }}
    />
  );
};

export default BGDot;
