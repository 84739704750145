import React from 'react';
import classNames from 'classnames';
import { Portal } from '@mui/base/Portal';
import { useLocation } from 'react-router-dom';
import styles from './MobileMenu.module.css';
import RouteLink from '@components/RouteLink/RouteLink';
import { getContactRoute, getDownloadRoute, getHomeRoute } from '@common/routes';

type Props = {
  open: boolean;
  className?: string;
  containerRef: React.RefObject<HTMLDivElement>;
  onBackdropClick?: () => void;
  onShowMobileLinkDialog?: () => void;
}

const MenuItem = (props: { children: React.ReactNode, target?: string }) => {
  const location = useLocation();
  const selected = location.pathname === props.target;
  return (
    <div className={classNames(styles.MenuItem, selected && styles.Selected)}>
      {props.target ? (
        <RouteLink target={props.target}>{props.children}</RouteLink>
      ) : (
        props.children
      )}
    </div>
  );
};


const MobileMenu = (props: Props) => {
  return (
    <Portal container={props.containerRef.current}>
      <div
        onClick={props.onBackdropClick}
        className={
          classNames(styles.Backdrop, {
            [styles.Open]: props.open
          })
        }
      />
      <div className={
        classNames(styles.MobileMenu, props.className,
          {
            [styles.Open]: props.open
          }
        )
      }>
        <MenuItem target={getHomeRoute()}>Home</MenuItem>
        {/* <MenuItem target="/pricing">Pricing</MenuItem>
        <MenuItem target="/about">About</MenuItem> */}
        <MenuItem target={getContactRoute()}>Contact</MenuItem>
        <MenuItem>
          <div onClick={props.onShowMobileLinkDialog}>Try for free</div>
        </MenuItem>
      </div>
    </Portal>
  );
};

export default MobileMenu;
