import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RouteLink.module.css';

type Props = {
  children: React.ReactNode;
  target: string;
}

const RouteLink = (props: Props) => {
  return (
    <Link className={styles.Link} to={props.target}>{props.children}</Link>
  );
};

export default RouteLink;
