import LandingPageLayout from '@layouts/LandingPageLayout';
import React from 'react';
import styles from './SuspenseLoading.module.css';

const SuspenseLoading = () => {
  return (
    <LandingPageLayout>
      <div className={styles.Loader}>

      </div>
    </LandingPageLayout>
  );
};

export default SuspenseLoading;
