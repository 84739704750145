import React from 'react';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import LandingPageLayout from '@layouts/LandingPageLayout';
import styles from './TermsAndConditions.module.css';

const TermsAndConditions = () => {
return (
<LandingPageLayout>
  <SectionContainer className={styles.Wrapper}>
    <div className={styles.Article}>
        <p className="c59 c114"><span className="c2" /></p>
        <p className="c59 c114"><span className="c2" /></p>
        <p className="c89"><span className="c25">&nbsp;</span></p>
        <p className="c132"><span className="c13 c55">APPLICATION AND WEBSITE TERMS &amp; CONDITIONS OF USE</span></p>
        <p className="c69"><span className="c25">&nbsp;</span></p>
        <p className="c59"><span>Last Updated: 12th December 2023</span></p>
        <p className="c75"><span className="c25">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">1.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c4">About imClever</span></p>
        <p className="c100"><span className="c4">&nbsp;</span></p>
        <p className="c44"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>Welcome to
            imClever (</span><span className="c13">Application and Website</span><span>). The Application and Website allows you
            to capture, store, view, access, edit, create, share and communicate smart screenshots and digital information.
            The provision of the Application and Website are our services (</span><span className="c13">Services</span><span className="c2">). &nbsp;</span></p>
        <p className="c73"><span className="c2">&nbsp;</span></p>
        <p className="c7"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>The Application
            and Website is operated by Cappy Pty Ltd (trading as imClever), ABN 59 654 679 393, headquartered in NSW,
            Australia. Access to and use of the Application and Website, or any of its associated products or Services, is
            provided by Cappy Pty Ltd (trading as imClever). Please read these terms and conditions (</span><span className="c13">Terms</span><span className="c2">) carefully. By accessing, using, browsing and/or reading the Application
            and/or Website, this signifies that you have read, understood and agree to be bound by the Terms. If you do not
            agree with the Terms, you must cease usage of the Application and Website, or any of Services, immediately.</span>
        </p>
        <p className="c77"><span className="c2">&nbsp;</span></p>
        <p className="c97 c147"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Cappy Pty Ltd (trading as imClever) reserves the right to review and change any of the Terms by
            updating this page at its sole discretion. When Cappy Pty Ltd (trading as imClever) updates the Terms, it will use
            reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate
            effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your
            records.</span></p>
        <p className="c112"><span className="c2">&nbsp;</span></p>
        <p className="c112"><span className="c2">&nbsp;</span></p>
        <p className="c145"><span className="c13">2.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c4">Use of the Services<br /><br /></span></p>
        <p className="c30"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>To access the
            Services, you must access our application, website or 3</span><span className="c93">rd</span><span>&nbsp;Party
            Integrations (</span><span className="c13">User</span><span className="c2">). As a User in any way accessing the Services,
            you agree to be bound by the Terms.</span></p>
        <p className="c30"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>If you as a
            User sign up to imClever via the application or website you will also be considered a signed-up member
            (</span><span className="c13">Registered User</span><span className="c2">). As a Registered User you agree to be bound by
            the Terms and any other terms that apply to Registered Users.</span></p>
        <p className="c44"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;</span><span>&nbsp;</span><span>&nbsp; As a User or Registered User in any way accessing the
            Services</span><span>, t</span><span>he screenshots, content, files and digital information captured, uploaded,
            stored, edited, created, communicated, and/or shared by you is your user owned and controlled content that you are
            responsible for (</span><span className="c13">User Content</span><span className="c2">).</span></p>
        <p className="c30"><span>(d)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">You
            retain all rights in, responsibility and liability for all User Content you capture, store, edit, communicate and
            share while using our Services. Cappy Pty Ltd (trading as imClever) does not claim ownership of your User Content.
            Our Services are created for your use as a User, and we have no control over what User Content is captured, stored
            or shared.</span></p>
        <p className="c30"><span>(e)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>You agree and
            understand that your User Content, may be programmatically accessed by Cappy Pty Ltd (trading as imClever) and our
            3</span><span className="c93">rd</span><span>&nbsp;Party technology providers in order to provide the Services or to
            improve the accuracy &amp; quality of our Services. To be clear, we use Machine Learning and AI to make
            screenshots and digital information “smart” and searchable. This means our own or 3</span><span className="c93">rd</span><span className="c2">&nbsp;party computers, computer algorithms and AI may access and use User
            Content in order to provide our Services or improve our Services. We make all attempts to keep User Content secure
            and anonymous, though please be aware hacks do happen to even the biggest and most secure technology companies.
            For more information on privacy, please refer to our Privacy Policy on our website imclever.io.</span></p>
        <p className="c30"><span>(f)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span>If you
            choose to share your User Content (</span><span className="c13">Shared User Content</span><span className="c2">) with
            anyone else or make it publicly available you understand that other Users or users on the Internet may view,
            access, save or store that Shared User Content to make it their own User Content. &nbsp;</span></p>
        <p className="c56"><span className="c2">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">3.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c4">Acceptance of the Terms</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c67"><span className="c2">As a User and/or Registered User, you accept the Terms by using, accessing, storing,
            User Content or remaining on the Application and Website. Where the option is available in the user interface, you
            may also accept the Terms by clicking to accept or agree to the Terms.</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">4.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c4">About the Service</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c30"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">imClever is a Machine Learning and AI powered smart screenshotting and communications application and
            website that lets you capture, store and share digital content. We provide the Application and Website to use as a
            utility for capturing and communicating legal User Content.</span></p>
        <p className="c39"><span className="c2">&nbsp;</span></p>
        <p className="c97 c137"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Free, Trial and Paid accounts are currently offered. You acknowledge and agree that the accounts
            offered, as well as the account features may change from time to time, and may be governed by separate terms which
            apply specific to the account. Where special account-specific terms apply, you will be informed, and must accept
            those terms before you are given such an account. For the avoidance of doubt these Terms apply unless otherwise
            amended by account-specific terms.</span></p>
        <p className="c77"><span className="c2">&nbsp;</span></p>
        <p className="c22"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Some
            accounts may be governed by a separate Software Licensing Agreement with Cappy Pty Ltd (trading as imClever),
            which may amend the terms of use. For the avoidance of doubt these Terms apply unless otherwise amended by the
            terms of an applicable Software Licensing Agreement.</span></p>
        <p className="c22 c114"><span className="c2" /></p>
        <p className="c19"><span className="c2" /></p>
        <ol className="c60 lst-kix_ex9q44y4mwsm-0 start" start={5}>
          <li className="c151 li-bullet-0 bold"><span className="c4">Acceptable use of the Service</span></li>
        </ol>
        <p className="c46"><span className="c4">&nbsp;</span></p>
        <p className="c97 c126"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">imClever, its related features, including the Application and Website must only be used lawfully. Cappy
            Pty Ltd (trading as imClever) reserves the right to suspend, cancel, or otherwise deny access to users and
            accounts who use the service:</span></p>
        <p className="c120"><span className="c2">&nbsp;</span></p>
        <p className="c17"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">To
            engage in any act that would disrupt the access, availability, and security of imClever and other Cappy Pty Ltd
            (trading as imClever) services, including but not limited to:</span></p>
        <p className="c45"><span className="c2">&nbsp;</span></p>
        <p className="c85"><span>(A)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Tampering with,
            reverse-engineering, or hacking our servers.</span></p>
        <p className="c21"><span className="c2">&nbsp;</span></p>
        <p className="c104"><span>(B)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Modifying,
            disabling, or compromising the performance of imClever or other Cappy Pty Ltd (trading as imClever)
            services.</span></p>
        <p className="c10"><span className="c2">&nbsp;</span></p>
        <p className="c12"><span>(C)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Overwhelming, or
            attempting to overwhelm our infrastructure by imposing an unreasonably large load on our systems that consume
            extraordinary resources.</span></p>
        <p className="c76"><span className="c2">&nbsp;</span></p>
        <p className="c115 c144"><span>(D)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Compromising
            the integrity of our system, including probing, scanning and testing the vulnerability of our system unless
            expressly permitted by Cappy Pty Ltd (trading as imClever).</span></p>
        <p className="c54"><span className="c2">&nbsp;</span></p>
        <p className="c16"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">For
            any illegal purpose, or to violate any laws, including and without limitation to data, privacy, and export control
            laws.</span></p>
        <p className="c39"><span className="c2">&nbsp;</span></p>
        <p className="c81"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">To
            stalk, harass or threaten users and any member of the public.</span></p>
        <p className="c21"><span className="c2">&nbsp;</span></p>
        <p className="c97 c150"><span>(d)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">To misrepresent or defraud any user or member of the public through phishing, spoofing, manipulating
            headers or other identifiers, impersonating anyone else, or falsely implying any sponsorship or association with
            Cappy Pty Ltd (trading as imClever) or any third party</span></p>
        <p className="c48"><span className="c2">&nbsp;</span></p>
        <p className="c122 c97"><span>(e)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">To access or search any part of the Service, or any other Service owned by Cappy Pty Ltd (trading as
            imClever) other than our publicly supported interface, or otherwise allowed for in an applicable Software
            Licensing Agreement.</span></p>
        <p className="c45"><span className="c2">&nbsp;</span></p>
        <p className="c65"><span>(f)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">To post, upload, share, or otherwise circulate content in violation of imClever’s content policy
            which may be amended from time to time at the absolute discretion of Cappy Pty Ltd (trading as imClever).</span>
        </p>
        <p className="c46"><span className="c2">&nbsp;</span></p>
        <ol className="c60 lst-kix_y098w24ys2zf-0 start" start={6}>
          <li className="c46 c118 li-bullet-1 bold"><span className="c4">Security and Data Privacy</span></li>
        </ol>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c68"><span className="c2">Cappy Pty Ltd (trading as imClever) takes your privacy seriously and Personal
            Information, User Content and any information provided through your use of the Application and/or Website and/or
            Services are subject to Cappy Pty Ltd (trading as imClever)’s Privacy Policy, which is available on our
            website imClever.io.</span></p>
        <p className="c62"><span className="c2" /></p>
        <p className="c42"><span className="c49">&nbsp;</span></p>
        <ol className="c60 lst-kix_jw3v0hee1dhx-0 start" start={7}>
          <li className="c78 li-bullet-2 bold"><span className="c4">Data Use</span></li>
        </ol>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c90"><span className="c18">Cappy Pty Ltd (trading as imClever) collects, stores, and processes your data and we
            use 3</span><span className="c18 c93">rd</span><span className="c8">&nbsp;Parties to store your data and provide our
            Services. The data is used to provide Services to you, as well as to facilitate Cappy Pty Ltd (trading as
            imClever)’s business operations.</span></p>
        <p className="c42"><span className="c4">&nbsp;</span></p>
        <p className="c103"><span className="c40 c13">8.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c4">Subscription to the Service</span></p>
        <p className="c46"><span className="c4">&nbsp;</span></p>
        <p className="c97 c128"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>If you
            have a paid account (</span><span className="c13">Subscription</span><span>) you must pay the applicable fee for the
            selected Subscription (</span><span className="c13">Subscription Fee</span><span>). As a Subscription you will be
            granted immediate access to paid features of the Services from the time you have completed the registration
            process until the subscription period expires (</span><span className="c13">Subscription</span><span>&nbsp;</span><span className="c13">Period</span><span className="c2">).</span></p>
        <p className="c48"><span className="c2">&nbsp;</span></p>
        <p className="c97 c105"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">In purchasing the Subscription, you acknowledge and agree that it is your responsibility to ensure that
            the Subscription you elect to purchase is suitable for your use.</span></p>
        <p className="c97 c107"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">As part of the sign up process, or as part of your continued use of the Services, you may be required
            to provide personal information about yourself (such as identification or contact details), including but not
            limited to name, email address and phone number.</span></p>
        <p className="c35 c97"><span>(d)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">You warrant that any information you give to Cappy Pty Ltd (trading as imClever) in the course of
            completing the sign up process or using the Services will always be accurate, correct and up to date.</span></p>
        <p className="c35 c97"><span>(e)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">You may not use the Services and may not accept the Terms if:</span></p>
        <p className="c35"><span className="c2">&nbsp;</span></p>
        <p className="c15"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">you
            are not of legal age to form a binding contract with Cappy Pty Ltd (trading as imClever); or</span></p>
        <p className="c35"><span className="c2">&nbsp;</span></p>
        <p className="c64"><span>(ii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">you are a
            person barred from receiving the Services under the laws of Australia or other countries including the country in
            which you are resident or from which you use the Services</span></p>
        <p className="c62"><span className="c2" /></p>
        <p className="c89"><span className="c49">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">9.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c4">Payments</span></p>
        <p className="c46"><span className="c4">&nbsp;</span></p>
        <p className="c28"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Subject to the terms of any applicable Software License Agreement, the Subscription Fee may be paid by
            all payment methods available on the Application and Website, and may change from time to time.</span></p>
        <p className="c10"><span className="c2">&nbsp;</span></p>
        <p className="c97 c123"><span className="c18">(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span><span className="c8">Payments made in the course of your use of imClever may be made using third-party
            applications and services not owned, operated, or otherwise controlled by Cappy Pty Ltd (trading as imClever). You
            acknowledge and agree that Cappy Pty Ltd (trading as imClever) will not be liable for any losses or damage arising
            from the operations of third-party payment applications and services. You further acknowledge and warrant that you
            have read, understood and agree to be bound by the terms and conditions of the applicable third-party payment
            applications and services you choose to use as a payment method for imClever services.</span></p>
        <p className="c36"><span className="c8">&nbsp;</span></p>
        <p className="c27"><span className="c18">(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;</span><span className="c8">You acknowledge and agree that where a request for the payment of the Subscription
            Fee is returned or denied, for whatever reason, by your financial institution or is unpaid by you for any other
            reason, then you are liable for any costs, including banking fees and charges, associated with the Subscription
            Fee.</span></p>
        <p className="c141"><span className="c8">&nbsp;</span></p>
        <p className="c97 c115"><span>(d)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">You agree and acknowledge that Cappy Pty Ltd (trading as imClever) can vary the Subscription Fee at any
            time and that the varied Subscription Fee will come into effect following the conclusion of the existing
            Subscription.</span></p>
        <p className="c26"><span className="c2">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">10.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c4">Refund Policy</span></p>
        <p className="c46"><span className="c4">&nbsp;</span></p>
        <p className="c35 c97"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>Cappy Pty
            Ltd (trading as imClever) offers refunds in accordance with the </span><span className="c88">Australian Consumer
            Law</span><span>&nbsp;and on the terms set out in these terms and conditions. Any benefits set out in these terms
            and conditions may apply in addition to consumer&apos;s rights under the </span><span className="c88">Australian
            Consumer Law</span><span className="c2">.</span></p>
        <p className="c20"><span className="c2">&nbsp;</span></p>
        <p className="c97 c129"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>Cappy Pty
            Ltd (trading as imClever) will only provide you with a refund of the Subscription Fee in the event they are unable
            to continue to provide the Services or if the manager of Cappy Pty Ltd (trading as imClever) makes a decision, at
            its absolute discretion, that it is reasonable to do so under the circumstances. Where this occurs, the refund
            will be in the proportional amount of the Subscription Fee that remains unused by the member (</span><span className="c13">Refund</span><span className="c2">).</span></p>
        <p className="c148"><span className="c2">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">11.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">Copyright
            and Intellectual Property</span></p>
        <p className="c46"><span className="c4">&nbsp;</span></p>
        <p className="c50"><span className="c18">(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c18">The Application and Website, the Services and all of the related products of Cappy Pty Ltd (trading as
            imClever) are subject to copyright. Unless otherwise indicated, all rights (including copyright) in the Services
            and compilation </span><span className="c2">of the Application and Website (including but not limited to text,
            graphics, logos, button icons, video images, audio clips, Application and Website code, scripts, design elements
            and interactive features) or the Services are owned or controlled for these purposes, and are reserved by Cappy
            Pty Ltd (trading as imClever) or its contributors.</span></p>
        <p className="c36"><span className="c49">&nbsp;</span></p>
        <p className="c57"><span className="c18">(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c8">All trademarks, service marks and trade names are owned, registered and/or licensed by Cappy Pty Ltd
            (trading as imClever), who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you
            are a User to:</span></p>
        <p className="c149"><span className="c8">&nbsp;</span></p>
        <p className="c1"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">use
            the Application and Website pursuant to the Terms;</span></p>
        <p className="c21"><span className="c2">&nbsp;</span></p>
        <p className="c80 c113"><span>(ii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">copy
            and store the Application and Website and the material contained in the Application and Website in your
            device&apos;s cache memory; and</span></p>
        <p className="c61"><span className="c2">&nbsp;</span></p>
        <p className="c24"><span>(iii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">print pages from
            the Application and Website for your own personal and non-commercial use.</span></p>
        <p className="c142"><span className="c2">&nbsp;</span></p>
        <p className="c97 c122"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Cappy Pty Ltd (trading as imClever) does not grant you any other rights whatsoever in relation to the
            Application and Website or the Services. All other rights are expressly reserved by Cappy Pty Ltd (trading as
            imClever).</span></p>
        <p className="c10"><span className="c2">&nbsp;</span></p>
        <p className="c84"><span>(d)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Cappy Pty Ltd (trading as imClever) retains all rights, title and interest in and to the Application
            and Website and all related Services. Nothing you do on or in relation to the Application and Website will
            transfer any:</span></p>
        <p className="c45"><span className="c2">&nbsp;</span></p>
        <p className="c66"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">business name, trading name, domain name, trade mark, industrial design, patent, registered design or
            copyright, or</span></p>
        <p className="c39"><span className="c2">&nbsp;</span></p>
        <p className="c43"><span>(ii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">a right to
            use or exploit a business name, trading name, domain name, trade mark or industrial design, or</span></p>
        <p className="c61"><span className="c2">&nbsp;</span></p>
        <p className="c80 c109"><span>(iii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">a thing,
            system or process that is the subject of a patent, registered design or copyright (or an adaptation or
            modification of such a thing, system or process), to you.</span></p>
        <p className="c86"><span className="c2">&nbsp;</span></p>
        <p className="c5"><span>(e)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">You
            may not, without the prior written permission of Cappy Pty Ltd (trading as imClever) and the permission of any
            other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or
            play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise
            provided by these Terms.</span></p>
        <p className="c5 c114"><span className="c2" /></p>
        <p className="c5"><span className="c2">(f) &nbsp; &nbsp; When accessing our Services, any content you upload,capture, store or
            edit using the Application or Website is your User Content and is your responsibility. Take into consideration
            original copyright ownership of any User Content when you access our Services. </span></p>
        <p className="c23"><span className="c2">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">12.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">General
            Disclaimer</span></p>
        <p className="c62"><span className="c2" /></p>
        <p className="c46"><span className="c49">&nbsp;</span></p>
        <p className="c97 c140"><span className="c18">(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span><span className="c8">Nothing in the Terms limits or excludes any guarantees, warranties, representations or
            conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by
            law may not be limited or</span></p>
        <p className="c62"><span className="c2" /></p>
        <p className="c51 c112"><span className="c2">excluded.</span></p>
        <p className="c138"><span className="c49">&nbsp;</span></p>
        <p className="c81"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Subject to this clause, and to the extent permitted by law:</span></p>
        <p className="c34"><span className="c2">&nbsp;</span></p>
        <p className="c71"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">all
            terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are
            excluded; and</span></p>
        <p className="c61"><span className="c2">&nbsp;</span></p>
        <p className="c80 c121"><span>(ii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Cappy
            Pty Ltd (trading as imClever) will not be liable for any special, indirect or consequential loss or damage (unless
            such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer
            Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services
            or these Terms (including as a result of not being able to use the Services or the late supply of the Services),
            whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or
            otherwise.</span></p>
        <p className="c117"><span className="c2">&nbsp;</span></p>
        <p className="c82"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Use
            of the Application and Website and the Services is at your own risk. Everything on the Application and Website and
            the Services is provided to you &quot;as is&quot; and &quot;as available&quot; without warranty or condition of
            any kind. None of the affiliates, directors, officers, employees, agents, contributors and licensors of Cappy Pty
            Ltd (trading as imClever) make any express or implied representation or warranty about the Services or any
            products or Services (including the products or Services of Cappy Pty Ltd (trading as imClever)) referred to on
            the Application and Website. This includes (but is not restricted to) loss or damage you might suffer as a result
            of any of the following:</span></p>
        <p className="c116"><span className="c2">&nbsp;</span></p>
        <p className="c80 c108"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">failure of performance, error, omission, interruption, deletion, defect, failure to correct defects,
            delay in operation or transmission, computer virus or other harmful component, loss of data, communication line
            failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;</span>
        </p>
        <p className="c36"><span className="c2">&nbsp;</span></p>
        <p className="c80 c136"><span>(ii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">the
            accuracy, suitability or currency of any information on the Application and Website, the Services, or any of its
            Services related products (including third party material and advertisements on the Application and
            Website);</span></p>
        <p className="c10"><span className="c2">&nbsp;</span></p>
        <p className="c80 c130"><span>(iii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">costs
            incurred as a result of you using the Application and Website, the Services or any of the products of Cappy Pty
            Ltd (trading as imClever); and</span></p>
        <p className="c29"><span className="c2">&nbsp;</span></p>
        <p className="c80 c111"><span>(iv)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">the Services
            or operation in respect to links which are provided for your convenience.</span></p>
        <p className="c125"><span className="c2">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">13.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">Limitation of Liability</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c97 c127"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Cappy Pty Ltd (trading as imClever)&apos;s total liability arising out of or in connection with the
            Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under
            statute or otherwise, will not exceed the resupply of the Services to you.</span></p>
        <p className="c7"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">You
            expressly understand and agree that Cappy Pty Ltd (trading as imClever), its affiliates, directors, officers,
            employees, agents, contributors and licensors shall not be liable to you for any direct, indirect, incidental,
            special consequential or exemplary damages which may be incurred by you, however caused and under any theory of
            liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or
            indirectly), any loss of goodwill or business reputation and any other intangible loss.</span></p>
        <p className="c99"><span className="c2">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">14.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">Termination of Contract</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c30"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">The
            Terms will continue to apply until terminated by either you or by Cappy Pty Ltd (trading as imClever) as set out
            below.</span></p>
        <p className="c39"><span className="c2">&nbsp;</span></p>
        <p className="c81"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">If
            you want to terminate the Terms, you may do so by:</span></p>
        <p className="c34"><span className="c2">&nbsp;</span></p>
        <p className="c1"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">not
            renewing the Subscription prior to the end of the Subscription Period;</span></p>
        <p className="c21"><span className="c2">&nbsp;</span></p>
        <p className="c71"><span>(ii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>providing Cappy Pty
            Ltd (trading as imClever) with 30 days’ notice of your intention to terminate; email us at </span><span className="c101"><a className="c9" href="mailto:support@imclever.io">support@imclever.io</a></span></p>
        <p className="c29"><span className="c2">&nbsp;</span></p>
        <p className="c80 c94"><span>(iii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">closing your
            accounts for all of the services which you use, where Cappy Pty Ltd (trading as imClever) has made this option
            available to you.</span></p>
        <p className="c83"><span className="c2" /></p>
        <p className="c98"><span className="c2">&nbsp;</span></p>
        <p className="c97 c146"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Any notices pursuant to Clause 13.2 above should be sent, in writing, to Cappy Pty Ltd (trading as
            imClever) via the &apos;Contact Us&apos; link on our homepage.</span></p>
        <p className="c39"><span className="c2">&nbsp;</span></p>
        <p className="c31"><span>(d)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Cappy Pty Ltd (trading as imClever) may at any time, terminate the Terms with you if:</span></p>
        <p className="c29"><span className="c2">&nbsp;</span></p>
        <p className="c1"><span>(i)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">you do
            not renew the Subscription at the end of the Subscription Period;</span></p>
        <p className="c21"><span className="c2">&nbsp;</span></p>
        <p className="c80 c91"><span>(ii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">you
            have breached any provision of the Terms or intend to breach any provision;</span></p>
        <p className="c39"><span className="c2">&nbsp;</span></p>
        <p className="c1"><span>(iii)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Cappy Pty Ltd
            (trading as imClever) is required to do so by law;</span></p>
        <p className="c6"><span className="c2">&nbsp;</span></p>
        <p className="c80 c124"><span>(iv)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">the
            provision of the Services to you by Cappy Pty Ltd (trading as imClever) is, in the opinion of Cappy Pty Ltd
            (trading as imClever), no longer commercially viable.</span></p>
        <p className="c86"><span className="c2">&nbsp;</span></p>
        <p className="c72"><span>(e)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">Subject to local applicable laws, Cappy Pty Ltd (trading as imClever) reserves the right to discontinue
            or cancel your Subscription or Account at any time and may suspend or deny, in its sole discretion, your access to
            all or any portion of the Application and Website or the Services without notice if you breach any provision of
            the Terms or any applicable law or if your conduct impacts Cappy Pty Ltd (trading as imClever)&apos;s name or
            reputation or violates the rights of those of another party.</span></p>
        <p className="c41"><span className="c49">&nbsp;</span></p>
        <p className="c53"><span className="c13 c37">15.</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c13 c37 c134">Indemnity</span></p>
        <p className="c38"><span className="c49">&nbsp;</span></p>
        <p className="c95"><span className="c8">You agree to indemnify Cappy Pty Ltd (trading as imClever), its affiliates, directors,
            officers, employees, agents, contributors, third party content providers and licensors from and against:</span>
        </p>
        <p className="c131"><span className="c49">&nbsp;</span></p>
        <p className="c58"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">all
            actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full
            indemnity basis) incurred, suffered or arising out of or in connection with your content or data;</span></p>
        <p className="c10"><span className="c2">&nbsp;</span></p>
        <p className="c96"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">any
            direct or indirect consequences of you accessing, using or transacting on the Application and Website or attempts
            to do so; and/or</span></p>
        <p className="c61"><span className="c2">&nbsp;</span></p>
        <p className="c81"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c2">any
            breach of the Terms.</span></p>
        <p className="c69"><span className="c2">&nbsp;</span></p>
        <p className="c52"><span className="c2">&nbsp;</span></p>
        <p className="c53"><span className="c13 c40">16.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">Dispute
            Resolution</span></p>
        <p className="c102"><span className="c4">&nbsp;</span></p>
        <p className="c47"><span className="c3">16.1. &nbsp;Compulsory:</span></p>
        <p className="c87"><span className="c49">&nbsp;</span></p>
        <p className="c105 c51"><span className="c2">If a dispute arises out of or relates to the Terms, either party may not commence
            any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with
            (except where urgent interlocutory relief is sought).</span></p>
        <p className="c106"><span className="c49">&nbsp;</span></p>
        <p className="c47"><span className="c3">16.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notice:</span></p>
        <p className="c6"><span className="c49">&nbsp;</span></p>
        <p className="c14"><span>A party to the Terms claiming a dispute (</span><span className="c13">Dispute</span><span className="c2">) has arisen under the Terms, must give written notice to the other party detailing the nature of the
            dispute, the desired outcome and the action required to settle the Dispute.</span></p>
        <p className="c98"><span className="c49">&nbsp;</span></p>
        <p className="c47"><span className="c3">16.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resolution:</span></p>
        <p className="c6"><span className="c49">&nbsp;</span></p>
        <p className="c51 c110"><span>On receipt of that notice (</span><span className="c13">Notice</span><span>) by that other
            party, the parties to the Terms (</span><span className="c13">Parties</span><span className="c2">) must:</span></p>
        <p className="c135"><span className="c49">&nbsp;</span></p>
        <p className="c74"><span>(a)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">Within 28
            days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means
            upon which they may mutually agree;</span></p>
        <p className="c10"><span className="c2">&nbsp;</span></p>
        <p className="c35 c80"><span>(b)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">If for
            any reason whatsoever, 28 days after the date of the Notice, the Dispute has not been resolved, the Parties must
            either agree upon selection of a mediator or request that an appropriate mediator be appointed by the Australian
            Mediation Association;</span></p>
        <p className="c48"><span className="c2">&nbsp;</span></p>
        <p className="c80 c143"><span>(c)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">The
            Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the
            mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a
            precondition to the mediation commencing. The Parties must each pay their own costs associated with the
            mediation;</span></p>
        <p className="c79"><span className="c2">&nbsp;</span></p>
        <p className="c1"><span>(d)</span><span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">The mediation
            will be held in Sydney, Australia.</span></p>
        <p className="c47"><span className="c3">&nbsp;</span></p>
        <p className="c47"><span className="c3">&nbsp;</span></p>
        <p className="c47"><span className="c3">16.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Confidential</span></p>
        <p className="c87"><span className="c49">&nbsp;</span></p>
        <p className="c35 c51"><span className="c2">All communications concerning negotiations made by the Parties arising out of and
            in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as
            &quot;without prejudice&quot; negotiations for the purpose of applicable laws of evidence.</span></p>
        <p className="c119"><span className="c49">&nbsp;</span></p>
        <p className="c47"><span className="c3">16.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Termination of Mediation:</span>
        </p>
        <p className="c87"><span className="c49">&nbsp;</span></p>
        <p className="c51 c92"><span className="c2">If 2 months have elapsed after the start of a mediation of the Dispute and the
            Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must
            do so.</span></p>
        <p className="c69"><span className="c49">&nbsp;</span></p>
        <p className="c32"><span className="c49">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">17.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">Venue and
            Jurisdiction</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c35 c133"><span className="c2">The Services offered by Cappy Pty Ltd (trading as imClever) is intended to be
            accessed and viewed by residents of Australia. In the event of any dispute arising out of or in relation to the
            Application and Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of
            New South Wales.</span></p>
        <p className="c139"><span className="c4">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">18.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">Governing
            Law</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c70"><span className="c2">The Terms are governed by the laws of New South Wales. Any dispute, controversy,
            proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created
            hereby shall be governed, interpreted and construed by, under and pursuant to the laws of New South Wales,
            Australia without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this
            governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their
            successors and assigns.</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c53"><span className="c40 c13">19.</span><span className="c0">&nbsp; &nbsp; &nbsp; </span><span className="c4">Severance</span></p>
        <p className="c11"><span className="c4">&nbsp;</span></p>
        <p className="c63"><span className="c18">If any part of these Terms is found to be void or unenforceable by a Court of
            competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.</span></p>
    </div>
  </SectionContainer>
</LandingPageLayout>
  );
};

export default TermsAndConditions;
