import React, { useEffect, useRef } from 'react';
import Footer from '@components/Footer/Footer';
import NavigationBar from '@components/NavigationBar/NavigationBar';
import styles from './LandingPageLayout.module.css';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  onShowMobileLinkDialog?: () => void;
}

const LandingPageLayout = (props: Props) => {
  const pageContainerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.PageLayoutContainer} ref={pageContainerRef}>
      <NavigationBar
        containerRef={pageContainerRef}
        onShowMobileLinkDialog={props.onShowMobileLinkDialog}
      />
      <div className={styles.ContentContainer}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
