import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import SuspenseLoading from '@pages/SuspenseLoading/SuspenseLoading';
import { getContactRoute, getDownloadRoute, getHomeRoute, getPrivacyPolicyRoute, getTermsOfUseRoute } from '@common/routes';
import TermsAndConditions from '@pages/Legal/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '@pages/Legal/PrivacyPolicy/PrivacyPolicy';

const Home = React.lazy(() => import('@pages/Home/Home'));
const ContactUs = React.lazy(() => import('@pages/ContactUs/ContactUs'));
const AppDownload = React.lazy(() => import('@pages/AppDownload/AppDownload'));

const App = () => {
  return (
    <div id="clever-page">
      <BrowserRouter>
        <Suspense fallback={<SuspenseLoading />}>
          <Routes>
            <Route path={getHomeRoute()} element={<Home />} />
            <Route path={getContactRoute()} element={<ContactUs />} />
            <Route path={getDownloadRoute()} element={<AppDownload />} />
            <Route path={getTermsOfUseRoute()} element={<TermsAndConditions />} />
            <Route path={getPrivacyPolicyRoute()} element={<PrivacyPolicy />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
