import React from 'react';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import LandingPageLayout from '@layouts/LandingPageLayout';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <LandingPageLayout>
      <SectionContainer className={styles.Wrapper}>
        <div className={styles.Article}>
            <p className="c43"><span className="c7 c57">PRIVACY POLICY</span></p>
            <p className="c59"><span className="c0">&nbsp;</span></p>
            <p className="c58"><span className="c0">Last Updated: 12th December 2023</span></p>
            <p className="c10"><span className="c0">Cappy Pty Ltd (trading as imClever)</span></p>
            <p className="c24"><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c7">1.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">We respect
                your privacy</span></p>
            <p className="c15"><span className="c8 c7">&nbsp;</span></p>
            <p className="c33"><span>(a)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">Cappy Pty Ltd
                (trading as imClever) respects your right to privacy and is committed to safeguarding the privacy of our customers
                and software application users.</span></p>
            <p className="c53"><span className="c0">&nbsp;</span></p>
            <p className="c52 c68"><span>(b)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c7">Personal
                information </span><span>means information we hold which is identifiable as</span><span className="c7">&nbsp;</span><span className="c0">being about you.</span></p>
            <p className="c69"><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c7">2.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">Collection
                of personal information</span></p>
            <p className="c15"><span className="c8 c7">&nbsp;</span></p>
            <p className="c52 c64"><span>(a)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">Cappy Pty
                Ltd (trading as imClever) will, from time to time, receive and store personal information you enter onto our
                software application imClever, our website, provide to us directly or give to us in other forms.</span></p>
            <p className="c5"><span className="c0">&nbsp;</span></p>
            <p className="c45"><span>(b)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">You may provide
                basic information such as your name, phone number, address and email address to enable us to send information,
                provide updates and process your product or service order. We may collect additional information at other times,
                including but not limited to, when you provide feedback, when you provide information about your personal or
                business affairs, change your content or email preference, respond to surveys and/or promotions, provide financial
                or credit card information, or communicate with our customer support.</span></p>
            <p className="c23"><span className="c0">&nbsp;</span></p>
            <p className="c47"><span>(c)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">Additionally,
                we may also collect any other information you provide while interacting with us.</span></p>
            <p className="c35"><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c7">3.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">How we
                collect your personal information</span></p>
            <p className="c15"><span className="c8 c7">&nbsp;</span></p>
            <p className="c27 c46"><span className="c0">Cappy Pty Ltd (trading as imClever) collects personal information from you in a
                variety of ways, including when you interact with us electronically or in person, when you access our software
                application and when we provide our services to you. We may receive personal information from third parties. If we
                do, we will protect it as set out in this Privacy Policy.</span></p>
            <p className="c62"><span className="c8 c7">&nbsp;</span></p>
            <p className="c1"><span className="c7">4.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">Use of your
                personal information</span></p>
            <p className="c15"><span className="c8 c7">&nbsp;</span></p>
            <p className="c32"><span>(a)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">Cappy Pty Ltd
                (trading as imClever) may use personal information collected from you to provide you with information, updates and
                our services. We may also make you aware of new and additional products, services and opportunities available to
                you. We may use your personal information to improve our products and services and better understand your
                needs.</span></p>
            <p className="c12"><span className="c0">&nbsp;</span></p>
            <p className="c10 c52"><span>(b)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">The
                Application may make third party social media features available to its users.</span></p>
            <p className="c2"><span className="c0" /></p>
            <p className="c22 c66"><span className="c0">We cannot ensure the security of any information you choose to make public in a
                social media feature. Also, we cannot ensure that parties who have access to such publicly available information
                will respect your privacy.</span></p>
            <p className="c17"><span className="c0">&nbsp;</span></p>
            <p className="c52 c55"><span>(c)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">Cappy Pty
                Ltd (trading as imClever) may contact you by a variety of measures including, but not limited to telephone, email,
                sms or mail.</span></p>
            <p className="c11"><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c7">5.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">Disclosure
                of your personal information</span></p>
            <p className="c11"><span className="c8 c7">&nbsp;</span></p>
            <p className="c51"><span>(a)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">We may disclose
                your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or
                subcontractors insofar as reasonably necessary for the purposes set out in this Policy. Personal information is
                only supplied to a third party when it is required for the delivery of our services.</span></p>
            <p className="c41"><span className="c0">&nbsp;</span></p>
            <p className="c50"><span>(b)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">We may from
                time to time need to disclose personal information to comply with a legal requirement, such as a law, regulation,
                court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency
                request.</span></p>
            <p className="c18"><span className="c0">&nbsp;</span></p>
            <p className="c52 c65"><span>(c)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">We may also
                use your personal information to protect the copyright, trademarks, legal rights, property or safety of Cappy Pty
                Ltd (trading as imClever), its application, website and customers or third parties.</span></p>
            <p className="c5"><span className="c0">&nbsp;</span></p>
            <p className="c38"><span>(d)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">Information
                that we collect may from time to time be stored, processed in or transferred between parties located in countries
                outside of Australia.</span></p>
            <p className="c5"><span className="c0">&nbsp;</span></p>
            <p className="c52 c63"><span>(e)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">If there is
                a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to
                the extent permissible at law our user databases, together with any personal information and non-personal
                information contained in those databases. This information may be disclosed to a potential purchaser under an
                agreement to maintain confidentiality. We would seek to only disclose information in good faith and where required
                by any of the above circumstances.</span></p>
            <p className="c23"><span className="c0">&nbsp;</span></p>
            <p className="c40"><span>(f)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c0">By
                providing us with personal information, you consent to the terms of this Privacy Policy and the types of
                disclosure covered by this Policy. Where we disclose your personal information to third parties, we will request
                that the third party follow this Policy regarding handling your personal information.</span></p>
            <p className="c56"><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c7">6.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">Security of
                your personal information</span></p>
            <p className="c11"><span className="c8 c7">&nbsp;</span></p>
            <p className="c39"><span>(a)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">Cappy Pty Ltd
                (trading as imClever) is committed to ensuring that the information you provide to us is secure. In order to
                restrict unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial
                procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorised
                access, modification and disclosure.</span></p>
            <p className="c2"><span className="c0" /></p>
            <p className="c13"><span>(b)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">The
                transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any
                information that you transmit to us, or receive from us. Although we take measures to safeguard against
                unauthorised disclosures of information, we cannot assure you that personal information that we collect will not
                be disclosed in a manner that is inconsistent with this Privacy Policy.</span></p>
            <p className="c28"><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c7">7.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">Access to
                your personal information</span></p>
            <p className="c15"><span className="c8 c7">&nbsp;</span></p>
            <p className="c19"><span>(a)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>You may request details of
                personal information that we hold about you in accordance with the provisions of the </span><span className="c30">Privacy Act</span><span className="c0">&nbsp;1988(Cth). A small administrative fee may be payable for the
                provision of information. If you would like a copy of the information which we hold about you or believe that any
                information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at
                support@imclever.io.</span></p>
            <p className="c34"><span className="c0">&nbsp;</span></p>
            <p className="c48"><span>(b)</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span>We reserve the right to
                refuse to provide you with information that we hold about you, in certain circumstances set out in the
            </span><span className="c30">Privacy Act</span><span className="c0">.</span></p>
            <p className="c35"><span className="c0">&nbsp;</span></p>
            <p className="c26"><span className="c7">8.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c8 c7">Collection &amp; Storage of User Content</span></p>
            <p className="c35"><span className="c8 c7">&nbsp;</span></p>
            <p className="c22"><span className="c0">If you capture or otherwise add screenshots or files via the application or website,
                these are screenshots, links, edits, markups and metadata we store for you in the cloud. This is your “User
                Content” and is covered in our Terms of Use available on our website imclever.io. &nbsp;By signing up and
                using our app or accessing our website you are agreeing to our Terms of Use.</span></p>
            <p className="c35"><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c7">9.</span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c8 c7">Complaints
                about privacy</span></p>
            <p className="c15"><span className="c7 c8">&nbsp;</span></p>
            <p className="c64 c27"><span className="c0">If you have any complaints about our privacy practices, please feel free to send
                in details of your complaints to support@imclever.io. We take complaints very seriously and will respond shortly
                after receiving written notice of your complaint.</span></p>
            <p className="c4"><span className="c8 c7">&nbsp;</span></p>
            <p className="c1"><span className="c7">10.</span><span>&nbsp; &nbsp; &nbsp; </span><span className="c8 c7">Op out right</span></p>
            <p className="c15"><span className="c8 c7">&nbsp;</span></p>
            <p className="c3"><span className="c0">You can stop all collection of information by the Application easily by uninstalling
                the Application (use the standard uninstall processes as may be available as part of your device) and cease from
                accessing our Website or interacting with our business. You can also request to opt-out via email, at
                support@imclever.io.</span></p>
            <p className="c67"><span className="c8 c7">&nbsp;</span></p>
            <p className="c1"><span className="c7">11.</span><span>&nbsp; &nbsp; &nbsp; </span><span className="c8 c7">Changes to Privacy
                Policy</span></p>
            <p className="c15"><span className="c8 c7">&nbsp;</span></p>
            <p className="c6"><span className="c0">Please be aware that we may change this Privacy Policy in the future. We may modify
                this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our
                posting of the modifications on our website or notice board. Please check back from time to time to review our
                Privacy Policy.</span></p>
            <p className="c60"><span className="c8 c7">&nbsp;</span></p>
            <p className="c1"><span className="c7">12.</span><span>&nbsp; &nbsp; &nbsp; </span><span className="c8 c7">Software
                Application</span></p>
            <p className="c29"><span className="c8 c7">&nbsp;</span></p>
            <p className="c10 c27"><span className="c8 c7">11.1. &nbsp;When you use our Application</span></p>
            <p className="c14"><span className="c0">&nbsp;</span></p>
            <p className="c42"><span className="c0">When you come to our website or use our application we may collect certain information
                such as unique device ID, the IP address of your device, operating system, the type of internet browsers you use,
                and information about the way you use the Application. This information is used in an aggregated manner to</span>
            </p>
            <p className="c61"><span className="c0">&nbsp;</span></p>
            <p className="c10 c16"><span className="c0">analyse how people use our site and software, such that we can improve our
                service.</span></p>
            <p className="c49"><span className="c0">&nbsp;</span></p>
            <p className="c10 c31"><span className="c8 c7">11.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookies</span></p>
            <p className="c14"><span className="c0">&nbsp;</span></p>
            <p className="c16 c54"><span className="c0">We may from time to time use cookies on our website or software application.
                Cookies are very small files which a website uses to identify you when you come back to the website or application
                and to store details about your use of the website or application. Cookies are not malicious programs that access
                or damage your computer, tablet or smartphone. Most devices automatically accept cookies but you can choose to
                reject cookies by changing your devise settings. However, this may prevent you from taking full advantage of our
                application.</span></p>
            <p className="c20"><span className="c0">&nbsp;</span></p>
            <p className="c10 c31"><span className="c8 c7">11.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Automatic
                collection</span></p>
            <p className="c14"><span className="c0">&nbsp;</span></p>
            <p className="c16 c36"><span className="c0">The software Application may collect certain information automatically, including,
                but not limited to, the type of device you use, your devices unique device ID, the IP address of your device, your
                operating system, the type of Internet browsers you use, and information about the way you use the
                Application.</span></p>
            <p className="c37"><span className="c0">&nbsp;</span></p>
            <p className="c10 c31"><span className="c8 c7">11.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Third parties</span></p>
            <p className="c14"><span className="c0">&nbsp;</span></p>
            <p className="c16 c21"><span>Our software application uses third party technology companies to provide our Services
                including but not limited to: cloud storage, machine learning and AI technologies. In the application you may have
                links to other applications or websites not owned or controlled by us. These links are meant for your convenience
                only. Links to third party applications and websites do not constitute sponsorship or endorsement or approval of
                these third parties. Please be aware that Cappy Pty Ltd (trading as imClever) is not responsible for the privacy
                practises of other such 3</span><span className="c25">rd</span><span className="c0">&nbsp;party technology companies,
                applications or websites. We encourage our users to be aware, when they leave our application or website, to read
                the privacy statements of each and every application or website that collects personal identifiable
                information.</span></p>
            <p className="c44"><span className="c0">&nbsp;</span></p>
            <p className="c10 c31"><span className="c8 c7">11.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Geo - location</span></p>
            <p className="c14"><span className="c0">&nbsp;</span></p>
            <p className="c9"><span className="c0">When you visit the application, we may use GPS technology (or other similar technology)
                to determine your current location in order to determine the city you are located within and display a location
                map with relevant advertisements. We will not share your current location with other users or partners.</span></p>
            <p className="c2"><span className="c0" /></p>
        </div>
      </SectionContainer>
    </LandingPageLayout>
  );
};

export default PrivacyPolicy;
